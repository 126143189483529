import { useState, useMemo } from "react";
import { getCurrentPageData } from "src/components/Pagination/tools/currentPageData";
import { usePostsContext } from "../context/PostsProvider/PostsProvider";
import useFilterPosts from "./components/FilterPosts/useFilterPosts";
import { URLEntity } from "src/tools/UrlEntity/BuildInURL";
import { getNumOfPages } from "src/components/Pagination/tools/getNumOfPages";

function useAllPosts() {
  let pageSize = 3;
  let posts = usePostsContext();
  const { categories, categoryValue, setCategoryValue, filteredPosts } =
    useFilterPosts(posts);
  const numOfItems = filteredPosts.length;
  const pageQueryParam = useMemo(() => {
    return getInitialPageNumber(numOfItems, pageSize);
  }, [numOfItems]);
  const [currentPage, setCurrentPage] = useState(pageQueryParam);
  const currentPageItemsData = getCurrentPageData(
    filteredPosts,
    currentPage,
    pageSize
  );

  function getInitialPageNumber(numOfItems, pageSize) {
    const totalPageCount = getNumOfPages(numOfItems, pageSize);
    const url = new URLEntity();
    url.setURL(URLEntity.getCurrentUrl());
    let pageQueryParam = Number(url.getQueryParam("page"));
    if (pageQueryParam > totalPageCount || pageQueryParam < 1) {
      pageQueryParam = 1;
      url.setQueryParam("page", pageQueryParam);
      // https://stackoverflow.com/questions/10970078/modifying-a-query-string-without-reloading-the-page
      window.history.pushState({ path: url.getURL() }, "", url.getURL());
    }
    return pageQueryParam;
  }

  function handlePageChange(page) {
    const url = new URLEntity();
    url.setURL(URLEntity.getCurrentUrl());
    url.setQueryParam("page", page);
    window.history.pushState({ path: url.getURL() }, "", url.getURL());
  }

  const handleChangeFilter = (e) => {
    setCategoryValue(e.target.value);
    setCurrentPage(1);
  };

  return {
    numOfItems: numOfItems,
    currentPageItemsData,
    pageSize,
    currentPage,
    setCurrentPage,
    handlePageChange,
    handleChangeFilter,
    categories,
    categoryValue,
  };
}

export default useAllPosts;
