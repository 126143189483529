import { Link } from "react-router-dom";
import { useTransitionNavigate } from "src/hooks";

// https://github.com/theninthsky/client-side-rendering/blob/main/src/components/common/NavigationLink.tsx
const TransitionLink = (props) => {
  const { to, onClick, children, state, ...rest } = props;
  const navigate = useTransitionNavigate();

  const onLinkClick = (event) => {
    event.preventDefault();
    navigate(to, { ...(state && { state: state }) });
    onClick?.(event);
  };

  return (
    <Link
      to={to}
      onClick={onLinkClick}
      {...(state && { state: state })}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default TransitionLink;
