import { lazy } from "react";
import { StringToJSX } from "src/tools/StringToJSX";
import { replaceSpacesWithDashes } from "src/tools/other";
import { H1 } from "src/elements/Typography";
import { dateOptions } from "../../../common/dateOptions";
import useShortPost from "./useShortPost";
import { Restricted, TransitionLink } from "src/components";
import { Link } from "react-router-dom";
import "./ShortPost.scss";

const ShortPostToolbar = lazy(() =>
  import(
    /* webpackChunkName: 'ShortPostToolbar' */ "./components/ShortPostToolbar/ShortPostToolbar"
  )
);

function ShortPost(props) {
  const { handleClickNavigateURL, shortenBody } = useShortPost();
  const titleDashes = replaceSpacesWithDashes(props.title);
  const shortBody = shortenBody(props.body, 120);

  return (
    <article className="ShortPost">
      {/* a solution for slow downloading images from firebase storage
      //  https://stackoverflow.com/questions/67712241/downloading-files-from-firebase-storage-is-very-slow*/}
      <picture>
        <source type="image/webp" srcSet={props?.url?.[0]} />
        <source type="image/*" srcSet={props?.url?.[1]} />
        <img
          className="ShortPost__image ShortPost--skeleton-loader"
          alt={`article title: ${props.title}`}
          src={props?.url?.[1]}
          onClick={() => handleClickNavigateURL(`${props.id}/${titleDashes}`)}
          // https://stackoverflow.com/a/46673833
          // onError={(e) =>
          //   (e.target.src =
          //     "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7")
          // }
        />{" "}
      </picture>
      <section className="ShortPost__content">
        <H1 className="ShortPost__title">
          <TransitionLink
            className="ShortPost__link ShortPost--skeleton-loader"
            to={`${props.id}/${titleDashes}`}
          >
            {props.title}
          </TransitionLink>
        </H1>
        <p
          className="ShortPost__text ShortPost--skeleton-loader"
          onClick={() => handleClickNavigateURL(`${props.id}/${titleDashes}`)}
        >
          {StringToJSX(shortBody)}
        </p>
        {/* <Link className="ShortPost__link" to={`../${props.id}/${titleDashes}`}>
          read more
        </Link> */}
        <footer className="ShortPost__metadata">
          <time className="ShortPost__start" dateTime={props.date}>
            {new Date(props.date).toLocaleDateString("en-GB", dateOptions)}
          </time>
          <Restricted action="ShortPost--useToolbar">
            <ShortPostToolbar
              className="ShortPost__end"
              titleDashes={titleDashes}
              id={props.id}
            />
          </Restricted>
        </footer>
      </section>
    </article>
  );
}

export default ShortPost;

// use as a model, style inspired from
// https://www.einaimgdolot.com/
