import AddPostButton from "./components/AddPostButton/AddPostButton";
import ShortPost from "./components/ShortPost/ShortPost";
import FilterPosts from "./components/FilterPosts/FilterPosts";
import { MetaSEO, Restricted, Pagination } from "src/components";
import useAllPosts from "./useAllPosts";
import "./AllPosts.css";

function AllPosts(props) {
  // posts = posts[0] ? [posts[0], posts[1], posts[2]] : posts;
  // const posts = useAllPosts();
  // let posts = props.posts.length
  //   ? props.posts
  //   : [
  //       {
  //         id: null,
  //         data: {
  //           author: "",
  //           body: "",
  //           category: "",
  //           title: "",
  //           url: "",
  //         },
  //       },
  //     ];
  // let posts = props.posts;
  // posts = [
  //   {
  //     id: null,
  //     data: {
  //       author: "",
  //       body: "",
  //       category: "",
  //       title: "",
  //       url: "",
  //       // url: "/270-ring.svg",
  //     },
  //   },
  // ];
  const {
    numOfItems,
    pageSize,
    currentPage,
    setCurrentPage,
    currentPageItemsData,
    handlePageChange,
    handleChangeFilter,
    categories,
    categoryValue,
  } = useAllPosts();
  // if (!props.posts.length)
  //   return <div style={{ fontSize: "150px" }}>abcdefgh</div>;
  return (
    <div className="AllPosts">
      <MetaSEO title="All Posts" description="All posts of the blog" />
      <FilterPosts
        className="AllPosts__categoryFilter"
        categories={categories}
        value={categoryValue}
        onChange={handleChangeFilter}
      />
      <div className="AllPosts__cards">
        {currentPageItemsData.map((post) => (
          <ShortPost
            key={post.id}
            id={post.id}
            author={post.data.author}
            date={
              post.data.date?.toDate?.().toUTCString() ||
              new Date(post.data.date)
            }
            body={post.data.body}
            category={post.data.category}
            title={post.data.title}
            url={post.data.url}
          />
        ))}
      </div>
      <Pagination
        className="AllPosts__pagination"
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalCount={numOfItems}
        pageSize={pageSize}
        onPageChange={handlePageChange}
      />
      {/* <Pagination
        className="AllPosts__pagination"
        currentPage={3}
        numberOfItemsPerPage={10}
        numberOfItems={135}
        onPageChange={"callback function"}
        breakLabel="..."
        nextLabel="next >"
        previousLabel="< previous"
      /> */}
      <Restricted action="AllPosts--addPost">
        <AddPostButton />
      </Restricted>
    </div>
  );
}

export default AllPosts;
