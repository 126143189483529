// import { useNavigate } from "react-router-dom";
import { useStableNavigate } from "src/contexts/StableNavigateContext/StableNavigateContext";
import { useTransitionNavigate } from "src/hooks";
import { stripTagsFromString, shortenString } from "src/tools/other";

function useShortPost() {
  // useNavigate will cause re-renders even if we click a link that we are in right now.
  // https://shallowdepth.online/posts/2022/04/why-usenavigate-hook-in-react-router-v6-triggers-waste-re-renders-and-how-to-solve-it/?utm_source=devto&utm_medium=canonical
  // https://github.com/remix-run/react-router/issues/7634
  // const navigate = useNavigate();
  // const navigate = useStableNavigate();
  const navigate = useTransitionNavigate();

  const shortenBody = (body, lenOfStr) => {
    const rawBody = stripTagsFromString(body);
    const shortBody = shortenString(rawBody, lenOfStr);
    return shortBody;
  };

  const handleClickNavigateURL = (url) => {
    navigate(window.location.pathname + "/" + url);
    // window.location.href = window.location.pathname + "/" + url;
  };

  return {
    shortenBody,
    handleClickNavigateURL,
  };
}

export default useShortPost;
