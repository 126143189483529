import { ListBox, ListBoxOption } from "src/components";
import classNames from "classnames";
import "./FilterPosts.css";

function FilterPosts(props) {
  return (
    <div className={classNames("FilterPosts", props.className)}>
      <ListBox
        value={props.value}
        onChange={props.onChange}
        className="FilterPosts__select"
        label="Category"
      >
        <ListBoxOption className="FilterPosts__option" value="All">
          All
        </ListBoxOption>
        {props.categories.map((category, index) => (
          <ListBoxOption
            key={index}
            className="FilterPosts__option"
            value={category}
          >
            {category}
          </ListBoxOption>
        ))}
      </ListBox>
    </div>
  );
}

export default FilterPosts;
