import { TransitionNavLink } from "src/components";
import "./AddPostButton.css";

function AddPostButton() {
  return (
    <div className="AddPostButton">
      <TransitionNavLink className="AddPostButton__link" to={`addpost`}>
        Add Post
      </TransitionNavLink>
    </div>
  );
}

export default AddPostButton;
