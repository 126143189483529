import classNames from "classnames";
import "./Fieldset.css";

function Fieldset(props) {
  const { children, className, ...rest } = props;
  return (
    <fieldset className={classNames("Fieldset", className)} {...rest}>
      {children}
    </fieldset>
  );
}

export default Fieldset;
