// https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/
// https://github.com/mayankshubham/react-pagination/

import classnames from "classnames";
// https://www.veryicon.com/
import { ReactComponent as ChevronLeft } from "./icons/chevron-left-37.svg";
import usePagination from "./usePagination";
import "./Pagination.css";

function Pagination(props) {
  const { currentPage, className } = props;

  const {
    DOTS,
    paginationRange,
    onPageNumClick,
    onNext,
    onPrevious,
    lastPage,
  } = usePagination(props);

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  return (
    <ul className={classnames("Pagination", { [className]: className })}>
      <li
        className={classnames("Pagination__item", {
          "Pagination__item--disabled": currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <ChevronLeft className="Pagination__svg" />
      </li>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <li className="Pagination__item Pagination__item-dots">&#8230;</li>
          );
        }

        return (
          <li
            className={classnames("Pagination__item", {
              "Pagination__item--selected": pageNumber === currentPage,
            })}
            onClick={() => onPageNumClick(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames("Pagination__item", {
          "Pagination__item--disabled": currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <ChevronLeft className="Pagination__svg Pagination__svg--rotate-right" />
      </li>
    </ul>
  );
}

export default Pagination;
