import classNames from "classnames";
import "./Legend.css";

function Legend(props) {
  const { children, className, ...rest } = props;
  return (
    <legend className={classNames("Legend", className)} {...rest}>
      {children}
    </legend>
  );
}

export default Legend;
