// good articles
// https://medium.com/geekculture/how-to-conditionally-render-react-ui-based-on-user-permissions-7b9a1c73ffe2
// https://isamatov.com/react-permissions-and-roles/

// import { useLocation } from "react-router-dom";
import usePermissions from "src/hooks/usePermissions/usePermissions";

function Restricted(props) {
  const { isUserActionAllowed } = usePermissions();
  const userAllowed = isUserActionAllowed(props.action);

  if (!userAllowed) {
    return null;
  }

  return props.children;
}

export default Restricted;
